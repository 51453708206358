import React from 'react';
import './Team.scss';
import ceoImg from '../../assets/jay.png'
import cmoImg from '../../assets/jm.png'
import ctoImg from '../../assets/snowy.png';
import mImg from '../../assets/de.png';

const Team = () => {
  const members = [
    {
      photo: ceoImg,
      title: 'JAYSNFT',
      link: 'http://www.wealthyweasels.io',
      description: (
        <p>
          <b>Founder</b>
          <br />
          Real life builder and entrepreneur turned web3 builder and entrepreneur
        </p>
      ),
    },
    {
      photo: cmoImg,
      title: 'jmwilliams015',
      link: 'http://www.wealthyweasels.io',
      description: (
        <p>
          <b>Cofounder</b>
          <br />
        </p>
      ),
    },
    {
      photo: ctoImg,
      title: 'Snowy',
        link: 'http://www.wealthyweasels.io',
        description: (
        <p>
          <b>Cofounder</b>
          <br />
          Technical manager
        </p>
      ),
    },
    {
      photo: mImg,
      title: 'decircusmaster',
        link: 'http://www.wealthyweasels.io',
        description: (
        <p>
          <b>Marketing manager</b>
          <br />
          Digital marketing creative who threads between both the arts and sciences
        </p>
      ),
    },
  ];
  return (
    <section className='container-fluid team' id='team'>
      <div className='bg'>
        <h1>Wealthy Weasels</h1>
      </div>
      <div className='container'>
        <h2 data-aos='fade-up'>Wealthy Weasels Team</h2>
        <div className='row d-flex justify-content-center'>
          {members.map(({photo, title, link, description }, index) => (
            <div className='col-12 col-lg-4 col-md-6 p-4 p-md-5' key={index}>
              {/* <a href={link} target='_blank' rel='noreferrer'> */}
                <div className='why-card' data-aos='fade-up' >
                  <img src={photo} alt='member' className='img-fluid' />
                  <h4>{title}</h4>
                  {description}
                </div>
              {/* </a> */}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;
