import React from 'react';
import './Mint.scss';
const Mint = () => {
  return (
    <section className='container-fluid mint' id='mint'>
      <div className='bg'>
        <h1>Wealthy Weasels</h1>
      </div>
      <div className='container'>
        <h2>Mint</h2>
        <div className='btn-group mb-5'>
          <button className='primary'>Mint</button>
        </div>
        <div className='row'>
          <div className='tax-card'>
            <div>
              <h5>Supply: TBA,       Mint Price: TBA,       Mint Date: TBA </h5>
            </div>
          </div>  
        </div>
      </div>
    </section>
  );
};

export default Mint;
