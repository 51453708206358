import React from 'react';
import './RoadMap.scss';
import weasel from '../../assets/weasel.png';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const RoadMap = () => {
  return (
    <section className='img-fluid road-map' id='roadmap'>
      <div className='bg roadmap-image'>
        <h1>Wealthy Weasels</h1>
      </div>
      <div className='container-wrapper'>
        <div className='robo'>
          <img src={weasel} alt='roadmap' className='img-fluid' />
        </div>
        <div className='container'>
          <h2 data-aos='fade-up'>Roadmap</h2>
          <VerticalTimeline>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: 'linear-gradient(132.24deg, #800000 4.37%, #414de1 100%)', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  #593597' }}
              date="Q3 2022"
              iconStyle={{ background: '#573393', color: '#fff' }}
              // icon={<WorkIcon />}
            >
              <h3 className="vertical-timeline-element-title">Launch our educational campaign</h3>
              <h4 className="vertical-timeline-element-subtitle"> </h4>
              <p>
                Create weasels community <br /> launch NFT, Listings on Magic Eden <br /> Staking process implemented. <br /> Project incubator initiated. <br /> Release platform for community members to create first projects. <br /> Education campaign started to empower weasels further. 
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: 'linear-gradient(132.24deg, #800000 4.37%, #414de1 100%)', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  #593597' }}
              date="Q4 2022"
              iconStyle={{ background: '#573393', color: '#fff' }}
              // icon={<WorkIcon />}
            >
              <h3 className="vertical-timeline-element-title">Job hunting and hiring process.</h3>
              <h4 className="vertical-timeline-element-subtitle"> </h4>
              <p>
                Release platform to be used by entire Solana ecosystem to streamline job hunting and hiring process. <br /> Continue launching projects and creating jobs for our community members. <br /> Expand team. <br /> Revenue sharing reveal. <br /> Weasel Transformation.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: 'linear-gradient(132.24deg, #800000 4.37%, #414de1 100%)', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  #593597' }}
              date="Q1 2023"
              iconStyle={{ background: '#573393', color: '#fff' }}
              // icon={<WorkIcon />}
            >
              <h3 className="vertical-timeline-element-title"> Community incubated projects </h3>
              <h4 className="vertical-timeline-element-subtitle"> </h4>
              <p>
                Launch 2-3 community incubated projects creating at least 10 jobs for our community members. <br /> Airdrop to holders.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: 'linear-gradient(132.24deg, #800000 4.37%, #414de1 100%)', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  #593597' }}
              date="Q2 2023"
              iconStyle={{ background: '#573393', color: '#fff' }}
              // icon={<WorkIcon />}
            >
              <h3 className="vertical-timeline-element-title">To be revealed</h3>
              <h4 className="vertical-timeline-element-subtitle"> </h4>
              <p> </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: 'linear-gradient(132.24deg, #800000 4.37%, #414de1 100%)', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  #593597' }}
              date="Q3 2023"
              iconStyle={{ background: '#573393', color: '#fff' }}
              // icon={<WorkIcon />}
            >
              <h3 className="vertical-timeline-element-title">To be revealed</h3>
              <h4 className="vertical-timeline-element-subtitle"> </h4>
              <p> </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
              // icon={<StarIcon />}
            />
          </VerticalTimeline>
        </div>
      </div>
    </section>
  );
};

export default RoadMap;
