import React from 'react';
import './Footer.scss';
import discord from '../../assets/discord.svg';
import twitter from '../../assets/twitter.svg';
import magiceden from '../../assets/magiceden.png';

const Footer = () => {
  return (
    <section>
      <footer className='container-fluid footer'>
        <div className='container'>
          <h4>Copyright © 2022 Wealthy Weasels</h4>
          {/* <p className='mail'>contact@Metaversetoken.com</p> */}
          {/* <h4 className='address'>Contract Address : TO BE OUT SOON</h4> */}
          <p className='declaration'>
            Solana based platform 
          </p>
          <div>
            <a
              href='https://discord.gg/rbsuhx5eqV'
              target='_blank'
              rel='noreferrer'
            >
              <img src={discord} alt='discord' className='img-fluid sm mx-3' />
            </a>
            <a
              href='https://twitter.com/WealthyWeasels'
              target='_blank'
              rel='noreferrer'
            >
              <img src={twitter} alt='twitter' className='img-fluid sm mx-3' />
            </a>
            <a
              href='https://magiceden.io'
              target='_blank'
              rel='noreferrer'
            >
              <img src={magiceden} alt='magiceden' className='img-fluid sm mx-3' />
            </a>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default Footer;
