import React from 'react';
import './About.scss';
const About = () => {
  return (
    <section className='container-fluid tax-distribution' id='about'>
      <div className='container'>
        <h2>About us</h2>
        <div className='row'>
          <p>The Wealthy Weasels was created through a vision of solving real problems in the Solana ecosystem while benefiting the community. <br /> Many of us are here because we are seeking to build a better life. <br /> A life we enjoy on our own terms. Well this is where the weasels step in to assist you on this journey... </p>
          <p>The W.W. is a community exclusive project incubator which will provide jobs, web3 opportunities and benefits exclusively reserved for our community with each project we launch. <br /> From founding a project, to marketing, moderating and more we will advise and assist you on your path to full time web3. </p>
          <p>All the while the W.W. will be providing quality trusted projects to the Solana ecosystem. </p>
          <p>Once our community is established and our project incubator has operated successfully we will be releasing an application which will benefit anyone in the web3 Solana space looking to hire, seek employment or launch an NFT project. </p>
          <p>Weasels will receive first hand priority access and benefits.</p>
          <p>So what are you waiting for!? Hop in the burrows! </p>
          <div className='btn-group mb-5'>
            <a href='https://discord.gg/rbsuhx5eqV' className='m-auto'>
              <button className='primary'>Join to Discord</button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
