import React from 'react';
import './NavigationBar.scss';
import { Link } from 'react-scroll';
//assets
import hamburger from '../../assets/hamburger.png';
import twitter from '../../assets/twitter.svg';
import discord from '../../assets/discord.svg';
import magiceden from '../../assets/magiceden.png';

const NavigationBar = () => {
  return (
    <nav className='navbar navbar-expand-xxl navbar-dark bg-transparent'>
      <div className='container'>
        <div className='social-links'>
          <a
            href='https://discord.gg/rbsuhx5eqV'
            target='_blank'
            rel='noreferrer'
          >
            <img src={discord} alt='discord' className='img-fluid' />
          </a>
          <a
            href='https://twitter.com/WealthyWeasels'
            target='_blank'
            rel='noreferrer'
          >
            <img src={twitter} alt='twitter' className='img-fluid' />
          </a>
          <a
            href='https://magiceden.io'
            target='_blank'
            rel='noreferrer'
          >
            <img src={magiceden} alt='magiceden' className='img-fluid' />
          </a>
        </div>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarNav'
          aria-controls='navbarNav'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <img src={hamburger} alt='hamburger' className='img-fluid' />
        </button>
        <div className='collapse navbar-collapse' id='navbarNav'>
          <ul className='navbar-nav ms-auto'>
            <li className='nav-item ms-md-5'>
              <Link
                to='welcome'
                spy={true}
                smooth={true}
                duration={500}
                className='nav-link'
              >
                Home
              </Link>
            </li>
            <li className='nav-item ms-md-5'>
              <Link
                to='about'
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                className='nav-link'
              >
                About us
              </Link>
            </li>
            <li className='nav-item ms-md-5'>
              <Link
                to='mint'
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                className='nav-link'
              >
                Mint
              </Link>
            </li>
            <li className='nav-item ms-md-5'>
              <Link
                to='team'
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                className='nav-link'
              >
                Team
              </Link>
            </li>
            <li className='nav-item ms-md-5'>
              <Link
                to='roadmap'
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                className='nav-link'
              >
                Road Map
              </Link>
            </li>
            <li className='nav-item ms-md-5'>
              <Link
                to='faq'
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                className='nav-link'
              >
                FAQ
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;
