import React from 'react';
import './Faq.scss';
import arrowUpIcon from '../../assets/arrow-up.svg';
import arrowDownIcon from '../../assets/arrow-down.svg';
import AnimateHeight from 'react-animate-height';
const Faq = () => {
  const [height, setHeight] = React.useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
  });
  const toggleHeight = (id) => {
    if (height[id] === 0) {
      setHeight({ ...height, [id]: 'auto' });
    } else {
      setHeight({ ...height, [id]: 0 });
    }
    console.log(height);
  };
  const data = [
    {
      id: 1,
      question: 'When is mint?',
      answer: (
        <p>
          - TBA Keep an eye out in our discord for future announcements. 
        </p>
      ),
    },
    {
      id: 2,
      question: 'Is there a White list?',
      answer: (
        <p>
          - Yes, white list roles will be given out with our discord. These roles will be rewarded to community members who are active, helpful, genuine and positive towards the project. We will also have a gambling bot from which a certain amount of tokens claimed can be turned in for a white list role.  
        </p>
      ),
    },
    {
      id: 3,
      question: 'How will this application process work for incubated projects?',
      answer: (
        <p>
         - We will be accepting applications exclusively from our community members to pose project ideas to be launched by Wealthy Weasels. The Wealthy Weasels team will choice a selection of our top picks and present them to our community members. A poll will be presented to narrow down our top pick to launch this project. The applicant whose Idea has been chosen will be made a founder of the project, receive a portion of mint funds and claim their job position working full time in the web3 space.
        </p>
      ),
    },
    {
      id: 4,
      question: 'How can I apply for a job within an incubated project?',
      answer: (
        <>
          <p>
            - first and foremost you must Be a member of our community. You will then have the chance to apply to work for a project that peaks your interest within our discord. All projects will have advisors to assist you with your employment endeavors as needed. This is a great opportunity to kickstart your career in the web3 space. 
          </p>
          <p>
            - The applicant who envisioned this project will be made an admin in the discord and together with our team their vision will be brought to life. After mint the applicant will undergo a "probationary period" specified by the community in which we will hold their pay out in escrow. Once this probationary period is over the chosen applicant will receive 50% of the minting funds for their team and project. They will of course remain the "face" of their project which presents the opportunity to work full time in the space. Wealthy Weasels will retain 50% funds as well; 30% going to the WW team and 20% going to the WW Vault. These percentages are subject to change once our services are requested at higher rate. Changes to percentages and services will never be made without notice to our community!
          </p>
        </>
      ),
    },
  ];
  return (
    <section className='container-fluid faq'>
      <div className='container'>
        <h2 data-aos='fade-up'>Frequently Asked Questions</h2>
        <div className='row'>
          <div className='col-12 col-lg-12 p-4 p-md-5'>
            {data.map(({ question, answer, id }, index) => (
              <div className='faq-card-wrapper' key={index} data-aos='fade-up'>
                <div className='faq-card'>
                  <div className='title'>
                    <p className='question'>{question}</p>
                    <img
                      src={height[id] === 0 ? arrowDownIcon : arrowUpIcon}
                      alt='arrow down'
                      className='img-fluid'
                      onClick={() => toggleHeight(id)}
                    />
                  </div>
                  <AnimateHeight duration={500} height={height[id]}>
                    {answer}
                  </AnimateHeight>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
