import React from 'react';
import NavigationBar from '../navigation-bar/NavigationBar';
import './Welcome.scss';

const Welcome = () => {
  return (
    <section className='container-fluid welcome' id='welcome'>
      <div className='bg'></div>
      <div className='container-wrapper'>
        <div className='container' id='main'>
          <NavigationBar />
          <div className='content-wrapper'>
            <div className='content'>
              <h1>Welcome to Wealthy Weasels!</h1>
              <p>
                Creating the best Solana based platform for new projects and job opportunities.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
