import React, { useEffect } from 'react';
import Welcome from './components/welcome/Welcome';
import Roadmap from './components/roadmap/RoadMap';
import Mint from './components/mint/Mint';
import Team from './components/team/Team';
import About from './components/about/About';
import Faq from './components/faq/Faq';
import Footer from './components/footer/Footer';
import './styles/main.scss';
import Aos from 'aos';
import 'aos/dist/aos.css';

const App = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      offset: 0,
    });
  }, []);
  return (
    <>
      <Welcome />
      <About />
      <Mint />
      <Team />
      <Roadmap />
      <Faq />
      <Footer />
    </>
  );
};

export default App;
